import React, { useState } from "react";
import "../../styles/components/Button.css";
import axiosInstance from "../../Axios";

const ButtonContact = () => {
  const [isContactOpen, setIsContactOpen] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
    number: "",
  });
  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [formError, setFormError] = useState("");

  const toggleOpen = () => setIsContactOpen((prev) => !prev);

  const closeContactWindow = () => {
    setIsContactOpen(false);
    setFormError("");
  };

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [id]: value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const { name, email, message } = formData;

    if (!name || !email || !message) {
      setFormError("Please fill in all required fields.");
      return;
    }

    setLoading(true);

    try {
      const response = await axiosInstance.post("/api/send-email", formData);

      if (response.status === 200) {
        setSubmitted(true);
        setFormData({ name: "", email: "", number: "", message: "" });
        setFormError("");
      } else {
        setFormError("Failed to send email. Please try again.");
      }
    } catch (error) {
      setFormError("An error occurred. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const renderInput = (id, placeholder, type = "text") => (
    <label htmlFor={id} className="contact__form-label">
      <input
        type={type}
        id={id}
        className="contact__form-input"
        value={formData[id]}
        onChange={handleChange}
        placeholder={placeholder}
        required
      />
    </label>
  );

  return (
    <div className="contact">
      <button className="contact__main-button" onClick={toggleOpen}>
        Get In Touch
      </button>

      {isContactOpen && (
        <div className="contact__overlay" onClick={closeContactWindow}>
          <div
            className="contact__window"
            onClick={(e) => e.stopPropagation()}
          >
            <button
              className="contact__close-button"
              onClick={closeContactWindow}
              aria-label="Close Contact Form"
            >
              &times;
            </button>
            <h2 className="contact__title">Get In Touch</h2>
            <p className="contact__subtitle">
              I’m always open to discussing new projects.
            </p>

            {submitted ? (
              <div className="contact__success">
                Thank you for reaching out! I’ll get back to you soon.
              </div>
            ) : (
              <form onSubmit={handleSubmit} className="contact__form">
                {renderInput("name", "Name")}
                {renderInput("email", "Email")}
                {renderInput("number", "Phone Number")}
                {renderInput("message", "Your Message")}

                <button
                  type="submit"
                  className="contact__submit-button"
                  disabled={loading}
                >
                  {loading ? "Sending..." : "Send"}
                </button>
                {formError && (
                  <p className="contact__form-error">{formError}</p>
                )}
              </form>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default ButtonContact;
