import React from 'react';
import ProjectList from './ProjectList';
import './Projects.css';

const Projects = () => {
  const projectItems = [
    {
      id: 1,
      title: 'Generate Palette',
      description: 'Effortlessly generate colors in seconds with our Color Generator!',
      imageUrl: 'project/mycolors.webp',
      link: 'https://mycolors.pro/color-generator',
    },
    {
      id: 2,
      title: 'Ai Image Generator',
      description: 'Transform Your Creative Projects with MyColors',
      imageUrl: 'project/aimycolors.webp',
      link: 'https://ai.mycolors.pro/',
    },
    {
      id: 3,
      title: 'CodeRed',
      description: 'Freelance web developers, ready to bring your ideas to life',
      imageUrl: 'project/codered.webp',
      link: 'https://webkayq.site/',
    },
    {
      id: 4,
      title: 'HTML School',
      description: 'At HTML School, we offer comprehensive courses designed to help you master HTML',
      imageUrl: 'project/schooljs.webp',
      link: 'https://htmltagss.com/',
    },
    {
      id: 5,
      title: 'Extract color',
      description: 'Extract color from an image',
      imageUrl: 'project/colorextractor.webp',
      link: 'https://mycolors.pro/image-color-extractor',
    },
    {
      id: 6,
      title: 'Trending Color Palettes',
      description: 'Discover trending color palettes for your design projects.',
      imageUrl: 'project/trend.webp',
      link: '',
    },
    {
      id: 7,
      title: 'Telegram bot!',
      description: 'Explore HTML, CSS, JS, React, & React Native topics. AM',
      imageUrl: 'project/TelegramBot.webp',
      link: 'https://t.me/CodeRedHub',
    },
    {
      id: 8,
      title: 'Blog',
      description: 'Your go-to source for the latest software trends, reviews, tips, tutorials, and exclusive deals.',
      imageUrl: 'project/blog.webp',
      link: 'https://blog.devaura.site',
    },
  ];

  return (
    <div className="projects" id="projects">
      <h2>My Projects</h2>
      <ProjectList items={projectItems} itemsPerPage={4} />
    </div>
  );
};

export default Projects;
