import React from "react";
import "../../styles/components/pages/AboutMe.css";

// Reusable Skills Component
const SkillsList = ({ skills }) => (
  <div className="about__skills">
    <h3 className="about__skills-heading">Skills:</h3>
    <ul className="about__skills-list">
      {skills.map((skill, index) => (
        <li key={index} className="about__skills-item">
          {skill}
        </li>
      ))}
    </ul>
  </div>
);

const AboutMe = () => {
  const skills = [
    "JavaScript (React, Node.js)",
    "HTML5, CSS3 (Sass/SCSS)",
    "RESTful APIs and GraphQL",
    "Database Design (MongoDB, MySQL)",
    "Version Control (Git, GitHub)",
    "Responsive Web Design",
    "UI/UX Design Principles",
    "Agile Development Methodology",
  ];

  return (
    <section className="about" id="about">
      <div className="about__container">
        <article className="about__content">
          {/* Profile Picture Section */}
          <figure className="about__picture-container">
            <a href="/" aria-label="Home">
              <img
                src="img/my.png"
                alt="Portrait of Harut Azatyan"
                className="about__picture"
              />
            </a>
          </figure>

          {/* About Text Section */}
          <div className="about__text-container">
            <header className="about__header">
              <h2 className="about__heading">About Me</h2>
            </header>
            <p className="about__description">
              Hi, I'm Harut Azatyan, a seasoned full-stack web developer with a
              strong passion for coding and problem-solving. I specialize in
              creating web solutions that seamlessly blend functionality with
              aesthetics, aiming to enhance user experiences.
            </p>
            <div className="about__details">
              <p>
                I have extensive experience working collaboratively with brands
                and agencies worldwide. My focus is on designing and developing
                websites and applications that prioritize interaction, motion,
                and a visually compelling user experience.
              </p>
              <p>
                As a dedicated freelance developer, I am committed to
                delivering high-quality solutions tailored to the unique needs
                of each project. My goal is to contribute to making the web a
                better place by crafting elegant and efficient code.
              </p>
            </div>

            {/* Skills Section */}
            <SkillsList skills={skills} />

            {/* Testimonials Section */}
            <div className="about__testimonials">
              <h3>What People Say</h3>
              <div className="about__testimonial">
                <p>
                  "Harut is a highly skilled developer who truly understands the
                  needs of his clients. He delivered a top-notch product that
                  exceeded our expectations!"
                </p>
                <p>- John Doe, Tech Solutions Inc.</p>
              </div>
              <div className="about__testimonial">
                <p>
                  "I’ve worked with many developers in the past, but none have
                  been as dedicated and creative as Harut. Highly recommended!"
                </p>
                <p>- Jane Smith, Web Innovators LLC</p>
              </div>
            </div>
          </div>
        </article>
      </div>
    </section>
  );
};

export default AboutMe;
